<template>
  <q-page class="q-py-xl">
    <div class="row justify-end">
      <div class="col-8 q-pa-md text-h6 text-center">Halaman Data Pendaftar</div>
      <q-btn class="col-2 q-mr-md self-end" color="primary" @click="fetch" label="Refresh" />
    </div>

    <div class="q-pa-md">
      <q-markup-table flat square dense>
        <!-- table head -->
        <thead class="bg-blue-grey-14 text-white">
          <tr>
            <th style="width:10px;">No</th>
            <th class="text-left">Nama</th>
            <th class="text-left">email/username</th>
            <th class="text-center">wa</th>
            <th class="text-center">Verifikasi</th>
            <th class="text-center">Verifikasi Pembayaran</th>
            <!-- <th class="text-center">Berkas</th> -->
            <th class="text-center">PDF</th>
            <th class="text-center">Opsi</th>
          </tr>
        </thead>
        <!-- table body  -->
        <tbody v-if="listPendaftar.length" class="bg-blue-grey-1">
          <tr v-for="(d, i) in listPendaftar" :key="d.id">
            <td class="text-left">{{ i + 1 }}</td>
            <td class="text-left">{{ d.nama }}</td>
            <td class="text-left">{{ d.username }}</td>
            <td class="text-center">
              <a
                :href="'https://api.whatsapp.com/send?phone=62'+ d.no_hp.substr(1) +'&text= *[PPDB - SMP AFBS]* %0D%0D Assalaamu%27alaikum,%20' + d.nama"
                target="_blank"
              >
                <img :src="'./img/kontak/wa.png'" :alt="'kontak_wa'" style="width: 30px;" />
              </a>
            </td>
            <td class="text-center transfer">
              <q-btn
                dense
                flat
                no-caps
                v-show="d.file_transfer"
                :color="d.status=='Y'?'positive':'deep-orange-8'"
                :icon-right="d.status=='Y'?'verified':''"
                @click="selected = d; verifikasi = true"
                label="View"
              />
            </td>
            <td class="text-center">
              <q-btn
                dense
                flat
                no-caps
                v-show="d.file_bayar_1"
                label="View"
                :color="d.status_bayar_1=='Y'?'positive':'deep-orange-8'"
                :icon-right="d.status_bayar_1=='Y'?'verified':''"
                @click="ver_payment=true,selected = d;get_img()"
              />
            </td>
            <!-- <td class="text-center transfer"> <q-btn dense flat no-caps v-show="d.nisn" color="deep-orange-8" @click="selected = d; showFile = true" label="View" /> </td> -->
            <td class="text-center">
              <q-btn
                dense
                flat
                no-caps
                v-show="d.nisn"
                type="a"
                target="_blank"
                label="View"
                color="deep-orange-8"
                @click="goToFormFinal(d.nisn)"
              />
            </td>
            <td class="text-center">
              <q-btn
                dense
                flat
                no-caps
                label="DELETE"
                color="red-8"
                @click="confirm=true, hapus_data.nama=d.nama,hapus_data.id=d.id,hapus_data.idx=i"
              />
            </td>
          </tr>
        </tbody>
        <tbody v-else class="bg-green-1">
          <tr>
            <td class="text-center text-grey" colspan="99">tidak ada data</td>
          </tr>
        </tbody>
      </q-markup-table>
    </div>
    <q-dialog v-model="verifikasi" persistent>
      <q-card>
        <q-card-section>
          <img
            :src="'../data/pendaftar/' +selected.userid +'/' + selected.file_transfer"
            alt="file_trf"
            style="width: 400px;"
          />
        </q-card-section>
        <q-card-section class="text-subtitle1 q-py-sm text-center">
          Nominal Harus Bayar:
          <b>{{selected.nominal_bayar}}</b>
        </q-card-section>
        <q-card-section class="row justify-evenly q-gutter-md">
          <q-btn
            unelevated
            style="width: 120px;"
            color="grey"
            label="Kembali"
            @click="verifikasi = false"
            no-caps
          />
          <q-btn
            outline
            style="width: 120px;"
            color="light-blue-6"
            label="Tidak Sesuai"
            @click="updateStatus('N')"
            no-caps
          />
          <q-btn
            unelevated
            style="width: 120px;color:white;"
            color="light-blue-6"
            label="Sesuai"
            @click="updateStatus('Y')"
            no-caps
          />
        </q-card-section>
      </q-card>
    </q-dialog>

    <q-dialog v-model="showFile" persistent maximized>
      <q-card>
        <q-card-section class="row justify-center">
          <img
            v-if="shown == 'ktp'"
            :src="'../data/pendaftar/' +selected.userid +'/' + selected.file_ktp"
            alt="file_trf"
            style="width: 400px;"
          />
          <img
            v-else-if="shown == 'kk'"
            :src="'../data/pendaftar/' +selected.userid +'/' + selected.file_kk"
            alt="file_trf"
            style="width: 100%;"
          />
          <img
            v-else
            :src="'../data/pendaftar/' +selected.userid +'/' + selected.file_akte"
            alt="file_trf"
            style="width: 210mm;"
          />
        </q-card-section>
        <q-card-section class="row justify-evenly">
          <q-btn-group spread style="width: 600px;">
            <q-btn
              unelevated
              style="width: 120px;"
              color="light-blue-4"
              label="KTP"
              @click="shown = 'ktp'"
              no-caps
            />
            <q-btn
              unelevated
              style="width: 120px;"
              color="light-blue-5"
              label="KK"
              @click="shown = 'kk'"
              no-caps
            />
            <q-btn
              unelevated
              style="width: 120px;"
              color="light-blue-6"
              label="Akte"
              @click="shown = 'akte'"
              no-caps
            />
          </q-btn-group>
        </q-card-section>
        <q-card-actions class="absolute-top-right">
          <q-btn flat color="primary" icon="close" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <q-dialog class="bg-red-8" v-model="confirm" persistent>
      <q-card>
        <q-card-section class="row items-center">
          <q-avatar icon="warning" color="negative" text-color="white" />
          <span class="q-ml-sm text-h6 text-red-8">PERINGATAN!</span>
          <span
            class="q-ml-sm q-mt-sm"
          >Apa Anda Yakin Ingin Menghapus Data Pendaftar atas nama {{hapus_data.nama}}</span>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="Tidak" color="primary" v-close-popup />
          <q-btn flat @click="hapusPendaftar()" label="Ya" color="primary" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <q-dialog v-model="ver_payment" persistent>
      <q-card>
        <q-card-section class="row items-center q-pb-none">
          <div class="text-h6">Verivikasi Permbayaran</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup />
        </q-card-section>
        <q-card-section>
          <img
            :src="'../data/pendaftar/' + selected.userid +'/' + selected.file_bayar_1"
            alt="file_trf"
            style="width: 400px;"
          />
        </q-card-section>
        <q-card-section class="text-subtitle1 q-py-sm text-center">
          Minimum Bayar:
          <b>Rp. 1.500.000 ,-</b>
        </q-card-section>
        <q-card-section class="row justify-evenly">
          <q-btn
            outline
            style="width: 120px;"
            color="light-blue-6"
            label="Tidak Sesuai"
            @click="updateStatusGol('N')"
            no-caps
            v-close-popup
          />
          <q-btn
            unelevated
            style="width: 120px;color:white;"
            color="light-blue-6"
            label="Sesuai"
            @click="updateStatusGol('Y')"
            no-caps
            v-close-popup
          />
        </q-card-section>
      </q-card>
    </q-dialog>
  </q-page>
</template>

<style>
</style>

<script>
let token = localStorage.getItem("smp-afbs")
export default {
  name: 'HelloWorld',
  data(){
    return{
      gol_bayar:"1",
      tab: "1",
      confirm: false,
      ver_payment:false,
      listPendaftar: [],
      selected: {},
      verifikasi: false,
      showFile: false,
      shown: 'ktp',
      hapus_data:[
        {nama:""},
        {id:0},
        {idx:0},
      ]


    }
  },
  mounted(){

    this.$http.get("/list_pendaftar", {})
      .then(result => {
        console.log(result.data);
        this.listPendaftar = result.data
      })
  },
  methods:{
    fetch(){
      this.$http.get("/list_pendaftar", {})
      .then(result => {
        console.log(result.data);
        this.listPendaftar = result.data
      })
    },
    showNotifdel () {
      this.$q.notify({
        message: 'data berhasil dihapus',
        color: 'negative'
      })
    },
    updateStatus(val){
      
      this.selected.status = val

      this.$http.put( "/update_status/" + this.selected.id + '/' + val +'/'+ this.selected.username+'/'+ this.selected.name ,{
          headers: { Authorization: "Bearer " + token}
        })
        .then((result) => {

          
          });
          this.verifikasi = false
    },
    updateStatusGol(val){
      
      this.selected.status_bayar_1 = val

      this.$http.put( "/update_status_gol/" + this.selected.id + '/' + val ,{
        })
        .then((result) => {

          
          });
    },
    goToFormFinal(val){
      let routeData = this.$router.resolve({name: 'form-final', params:{nisn: val}});
      window.open(routeData.href, '_blank');
    },
    hapusPendaftar(){
      this.$http.get("/hapus_pendaftar/" + this.hapus_data.id, {}).then(async result => {});
      this.listPendaftar.splice(this.hapus_data.idx, 1);
      this.showNotifdel();
      this.hapus_data.nama="";
      this.hapus_data.id=this.hapus_data.idx=0;
    }

  }
}
</script>
